import React, { useState } from 'react'
import JobCard from './JobCard'
import "./jobpage.css"
import { jobOpeningsData } from './jobData'
import HeaderSection from '../components/header'
import image2 from ".././assets/gallerybgimg.png";
import CommonHeroSection from '../components/CommonHeroSection/CommonHeroSection'
import Footer from '../components/footerSection'

const JobPage = () => {

const [jobData, setJobData] = useState(jobOpeningsData)

console.log(jobData)

  return (
    <div className='mainJobContainer overflow-hidden'>
    <HeaderSection/>
    <CommonHeroSection
         imgName={image2}
        titleHead="Our Openings"
        extraContent={`Home > Our Openings`}
    />
        <div className='jobPageMainContainer mb-5'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <h3>Our Openings</h3>
                </div>
            </div>
            <div className='row'>
            {
                jobData.map((data)=>{
                    return <JobCard key={data.id} data={data}/>
                })
            }
              
               
            </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default JobPage