import React from "react";
import gallaryImg1 from "../../assets/gallery_image_one.jpg";
import gallaryImg2 from "../../assets/gallery_image_two.jpg";
import gallaryImg3 from "../../assets/gallery_image_three.jpg";
import gallaryImg4 from "../../assets/gallery_image_four.jpg";
import gallaryImg5 from "../../assets/gallery_image_five.jpg";
import gallaryImg6 from "../../assets/gallaryImg6.png";
import workpic1 from '../../assets/WorkPic1.jpg'
import workpic2 from '../../assets/WorkPic2.jpg'
import workpic3 from '../../assets/WorkPic3.jpg'
import workpic4 from '../../assets/WorkPic4.jpg'
import workpic5 from '../../assets/WorkPic5.jpg'
import workpic6 from '../../assets/WorkPic6.jpg'
import workpic7 from '../../assets/WorkPic7.jpg'
import workpic8 from '../../assets/WorkPic8.jpg'

import './ourgallary.css'

export default function GallaryView() {
  return (
    <div className="same_width_container">
      <h6 className="title_gallary_text">
        {" "}
        Our <span>Gallery</span>
      </h6>

      <div className="row_our_gallary_container">
        <div className="left_width_gallary_contanier">
          <img src={gallaryImg3} alt="gallary" loading="lazy" />
        </div>
        <div className="rgt_width_gallary_contanier">
          <img src={gallaryImg2} alt="gallary" loading="lazy" />
          <img src={gallaryImg1} alt="gallary" loading="lazy" />
        </div>
      </div>

      <div className="row_our_gallary_container row_our_gallary_container1">
        <div className="left_width_gallary_contanier left_width_gallary_contanier1">
          <img src={gallaryImg4} alt="gallary" loading="lazy" />
        </div>
        <div className="rgt_width_gallary_contanier">
          <img src={gallaryImg5} alt="gallary" loading="lazy" />
          {/* <img src={gallaryImg6} alt="gallary" loading="lazy" /> */}
        </div>
      </div>
      <div className="row_our_gallary_container row_our_gallary_container1">
        <div className="left_width_gallary_contanier left_width_gallary_contanier1">
          <img src={workpic1} alt="gallary" loading="lazy" />
        </div>
        <div className="rgt_width_gallary_contanier">
          <img src={workpic3} alt="gallary" loading="lazy" />
          <img src={workpic2} alt="gallary" loading="lazy" />
        </div>
      </div>
      <div className="row_our_gallary_container row_our_gallary_container1">
        <div className="left_width_gallary_contanier left_width_gallary_contanier1">
          <img src={workpic4} alt="gallary" loading="lazy" />
        </div>
        <div className="rgt_width_gallary_contanier">
          <img src={workpic5} alt="gallary" loading="lazy" />
          <img src={workpic6} alt="gallary" loading="lazy" />
        </div>

       

      </div>
      <div className="row_our_gallary_container row_our_gallary_container1">
          <div className="left_width_gallary_contanier left_width_gallary_contanier1">
            <img src={workpic7} alt="gallary" loading="lazy" />
          </div>
          <div className="rgt_width_gallary_contanier">
            <img src={workpic8} alt="gallary" loading="lazy" />
          </div>
          </div>
    </div>
  );
}
