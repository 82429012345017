import React from "react";
import "./expertsection.css";
import expertChimneyImg from '../../assets/expert_chimney_bg.png'
import { useNavigate } from "react-router-dom";

export default function ExpertSection() {

  const navigate = useNavigate()

  return (
    <div>
      <div className="same_width_container">
        <div className="left_rgt_expert_container">
          <div className="left_expert_container">
            <div className="left_expert_img_container">
              <img src={expertChimneyImg} alt="bg" loading="lazy" />
            </div>
          </div>
          <div className="rgt_expert_container">
            <div className="rgt_expert_content_container">
              <p>Your Local Chimney </p>
              <h6><span>E</span>xperts</h6>
              <p>
              Craving the crackle of a wood fire? Hoping to modernize your outdated interior with a
modern gas or electric fireplace? The Fireplace Store by Leduc Chimney can make it all possible. We
are your one-stop shop for all fireplace products. Our team of certified professionals has the expertise
and experience to help you make the best decision. 
              </p>
              <button onClick={()=>{
                navigate("/AboutUs")
              }}>Discover More</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
