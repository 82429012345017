import React, { useState } from "react";
import "../../../components/FAQsSection/faqssection.css";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import './faqpage.css'

export default function FaqComponent(props) {
  const [openIndex, setOpenIndex] = useState(0);

  const handleOpenExpand = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <div className="flex  justify-center items-center md:m-20 my-4">
      <div className=" flex w-[80%] md:w-[100%] bg-white flex-col justify-center items-center p-2 shadow-lg rounded-xl entire_faq_section">
        <div className="flex font-bold mb-7 text-[40px]">
          <h6>
            {props.firstSentence}{" "}
            <span className=" text-color-orange">{props.secSentence}</span>
          </h6>
        </div>

        {props.ExpandDataAll?.map((item, index) => {
          const isOpen = index === openIndex;
          return (
            <div key={index} className="up_down_expand_container">
              <div
                className="faq_up_container"
                onClick={() => handleOpenExpand(index)}
              >
                <p>{item?.question}</p>
                {isOpen ? <MdExpandLess /> : <MdExpandMore />}
              </div>
              {isOpen && (
                <div className="faq_down_container">
                  <p>{item?.answer}</p>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
