import React from "react";
import HeaderSection from "../../components/header";
import CommonHeroSection from "../../components/CommonHeroSection/CommonHeroSection";
import Footer from "../../components/footerSection";
import image2 from "../../assets/woodbgimg.png";
import ContactUsSection from "../../components/servicesSection/ContactUsSection";
import GallarySection from "./GallarySection";
import gImg from "../../assets/woodfireplaceimg.png";
import gImg1 from "../../assets/woodstoveimg.png";
import WhyChooseSection from "./WhyChooseSection";
import FaqComponent from "../faq/FAQPage/FaqComponent";
// import { listOfFaq } from "../../components/listofData";

export default function ServicePageWood() {
  let gallaryData = [
    {
      gallaryImg: gImg,
      headText: `Wood Burning`,
      highlightText: `Fireplace`,
      extraText: `These are typically built into 
            homes with masonry or metal constructions. These
            fireplaces feature an open hearth where logs can be 
            burned directly, radiating heat and creating a cozy 
            atmosphere.`,
      gallaryImg1: gImg1,
      headText1: `Wood Burning`,
      highlightText1: `Stoves`,
      extraText1: `They are free-standing units that 
            are made from cast iron or steel, these stoves feature 
            a closed combustion chamber with a glass door, 
            allowing for controlled burning and better heat retention. 
            It comes in various sizes and styles, from traditional to 
            contemporary. It can be a more environmentally friendly 
            option compared to open fireplaces since they burn
            wood more efficiently.`,
    },
  ];

  let titlesBox = [
    "Aromatic Ambience",
    "Traditional Flame",
    "Natural Resource Utilization",
    "Dependable source of heat",
  ];



  const listOfFaq  = [
    {
    question: "Do I need a permit to install a wood-burning fireplace or stove?",
    answer: ` Yes, you likely need a permit from your local building department. Requirements can vary, so check with your local authorities first.

`,
    },
    {
    question: "Can I install a wood-burning fireplace or stove myself?",
    answer: `It's not recommended for those without experience. Proper installation is crucial for safety and efficiency. Consider hiring a certified professional for proper installation.
`,
    },
    {
    question: "What is creosote and why is it important to remove it?",
    answer: `Creosote is a highly flammable byproduct of wood burning. Excessive buildup increases the risk of chimney fires.

`,
    },
    {
    question: "How often should I have my fireplace or stove inspected?",
    answer: `Yearly inspections are recommended by chimney sweeps
`,
    },
  
];


  return (
    <div>
      <HeaderSection />
      <CommonHeroSection
        imgName={image2}
        titleHead="Wood"
        extraContent={`Home > Our Services >Wood`}
      />

      <GallarySection
        gallaryImg={gallaryData[0]?.gallaryImg}
        extraContent={gallaryData[0]?.extraText}
        secSent={gallaryData[0]?.highlightText}
        firstSent={gallaryData[0]?.headText}
        gallaryImg1={gallaryData[0]?.gallaryImg1}
        extraContent1={gallaryData[0]?.extraText1}
        secSent1={gallaryData[0]?.highlightText1}
        firstSent1={gallaryData[0]?.headText1}
      />

      <WhyChooseSection
        firstSent={"Why Choose"}
        secSent={"Wood Flame?"}
        titlesBox={titlesBox}
      />
   <FaqComponent
        firstSentence={`Frequently Asked `}
        secSentence={`Questions`}
        thirdSentence={`Sweep - Breathe Easy This Winter.`}
        ExpandDataAll={listOfFaq}
        // faqImg={faqImg}
      />
      <ContactUsSection />
     
  
      <Footer />
    </div>
  );
}
