import React from "react";
import HeaderSection from "../../components/header";
import CommonHeroSection from "../../components/CommonHeroSection/CommonHeroSection";
import image2 from "../../assets/serviceBg.png";
import ChimneySweepSection from "./ChimneySweepSection";
import TestimonialSection2 from "../../components/TestimonialSection2";
import serviceman from '../../assets/chimneySweepBg1.png'
import Footer from "../../components/footerSection";
import {listOfFaqChimneySweep} from '../../components/listofData'
import faqImg from '../../assets/serviceBg.png'
import ContactUsSection from "../../components/servicesSection/ContactUsSection";
import FAQsSection from "../../components/FAQsSection";
import OurGlorySection from "../../components/OurGlorySection";
import './service.css'
import chimneySweepfaq from '../../assets/chimneySweepfaq.png'
import chimney1 from '../../assets/Chimney1.jpg'
import chimney2 from '../../assets/Chimney2.jpg'
import chimney3 from '../../assets/Chimney3.jpg'
import chimney4 from '../../assets/Chimney4.jpg'
import chimney5 from '../../assets/Chimney5.jpg'
import ChimneySweepServiceSection from "../../components/servicesSection/ChimneySweepServiceSection";


export default function ServicePage() {

  let images = [chimney1, chimney2, chimney3, chimney4, chimney5]

  return (
    <div>
      <HeaderSection />
      <CommonHeroSection
        imgName={image2}
        titleHead="Chimney Sweeping"
        extraContent={`Home > Our Services > Chimney Sweeping`}
      />
      <ChimneySweepServiceSection/>
      {/* <ChimneySweepSection
        firstSentence={`How often Do you Think you should`}
        secSentence={`Clean your Chimney`}
        thirdSentence={`?? 
      Bi-Annualy, Yes that’s right!!`}
        title={`Chimney Sweep:`}
        subTitle={`Flame it Safe!`}
        extraContent={`Don't let winter chills turn into fire safety scares! Leduc Chimney
      Sweep Ltd. cleans all the deposited soot, ash, and debris with 
     expert cleaning using specialized tools like brushes, rods and 
     Vacuums. Our meticulous service ensures your chimney is 
     prepped for peak performance and safety next season. Breathe 
     easy and enjoy cozy winter nights with a clean and efficient 
     fireplace – contact Leduc Chimney Sweep Ltd. Today!`}
     bgImg={serviceman}

      /> */}

      {/* <OurGlorySection
        images={images}
      /> */}

      <FAQsSection
           
        // firstSentence={`Don't Let Smoke Cloud Your Fun! `}
        // secSentence={`Leduc Chimney`}
        // thirdSentence={` Sweep - Breathe Easy This Winter.`}
        ExpandDataAll={listOfFaqChimneySweep}
        faqImg={chimneySweepfaq} 
        classAdd = {"chimneySweepClass"}
      />
      
      <ContactUsSection />

      <Footer />
    </div>
  );
}
