import React from "react";
import "./service.css";
import trueImg from "../../assets/circle_container.svg";

export default function WhyChooseSection(props) {
  return (
    <div className="why_choose_bg_container">
      <div className="same_width_container">
        <h6 className="title_choose_text_self">
          {" "}
          {props?.firstSent} <span>{props?.secSent}</span>
        </h6>
        <div className="four_box_row_container">
          {props.titlesBox?.map((item, index) => {
            return (
              <div key={index} className="four_box_row">
                <div className="four_box_row_icon">
                  <img src={trueImg} alt="true" loading="lazy" />
                </div>
                <p>{item}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
