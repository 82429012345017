import React, { useEffect, useState } from "react";
import "./chimneySweepSection.css";

export default function ChimneySweepSection(props) {
  const [isMobile, setIsMobile] = useState(false);

  const checkMobileSize = () => {
    setIsMobile(window.innerWidth > 767); // Adjust the threshold as needed
  };


  useEffect(() => {
    checkMobileSize(); // Initial setup

    // Event listener for window resize
    const handleResize = () => {
      checkMobileSize(); // Update state on resize
    };

    // Attach event listener when component mounts
    window.addEventListener("resize", handleResize);

    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);


  return (
    <div className="same_width_container">
      <div
        className="chimney_content_container"
        style={{ backgroundImage: `url(${props.bgImg})` }}
      >
        {props.beforeAfter && (
          <p>
            {props.firstSentence} <span>{props.secSentence}</span>
            {props.thirdSentence}
          </p>
        )}

        {props.trueAfter && (
          <p>
            {props.firstContent} <br />
            {props.secSentence}
            <span>&nbsp;{props.highlightText}</span>
            &nbsp;{props.thirdSentence}
          </p>
        )}


        {isMobile &&
          <div className="box_chimney_container1">
            <p>
              {props.title} <span>{props.subTitle}</span>
            </p>
            <p>{props.extraContent}</p>
          </div>
        }


      </div>


      {!isMobile &&
        <div className="box_chimney_container1">
          <p>
            {props.title} <span>{props.subTitle}</span>
          </p>
          <p>{props.extraContent}</p>
        </div>
      }
    </div>
  );
}
