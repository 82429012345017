import React, { useEffect, useState } from "react";
import "./whereItStartedSection.css";
import bgImg from '../../../assets/whereItAllBg.png'
import bgImg1 from '../../../assets/about2.png'

import profileImg from '../../../assets/profile_pic_2.png'

export default function WhereItStartedSection() {
  const [isMobile, setIsMobile] = useState(false);

  const checkMobileSize = () => {
    setIsMobile(window.innerWidth > 767); // Adjust the threshold as needed
  };


  useEffect(() => {
    checkMobileSize(); // Initial setup

    // Event listener for window resize
    const handleResize = () => {
      checkMobileSize(); // Update state on resize
    };

    // Attach event listener when component mounts
    window.addEventListener("resize", handleResize);

    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  return (
    <div>
      <div className="same_width_container">
        <div className="started_wrapper">
          <div className="left_width_started_container">
            <div className="img_stated_wrapper">
              <img src={!isMobile ? bgImg1 : bgImg} alt="bgImg" loading="lazy" />
            </div>
          </div>
          <div className="right_width_started_container">
            <h6>Where it all</h6>
            <p>Began</p>

            <p>
            Leduc Chimney Sweep Ltd. was established in 2001. Originally operated as a home
business, our first showroom opened in Leduc in 2013. Leduc Chimney Sweep Ltd.
moved to our current showroom location in 2022 and became known as The Fireplace
Store by Leduc Chimney Sweep. We have continued to grow and now offer our
services throughout a larger footprint of central Alberta. Our services consist of all
fireplace and chimney related installations, parts, and repairs. Our technicians are
WETT Certified. This certification is recognized by Insurance companies relating to
woodburning appliances. Our attention to detail and professional services has helped
to create a solid repeat customer base and positive referral system.{" "}
            </p>

             {/*<div className="profile_text_container">
              <div className="profile_stated_img_container">
                <img src={profileImg} alt="profile_img" loading="lazy" />
              </div>
              <div className="title_subtitle_container">
                <p>Jessica Brown</p>
                <p>Founder of Comapny</p>
              </div> 
            </div>*/}
          </div>
        </div>
      </div>
    </div>
  );
}
