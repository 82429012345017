import React from "react";
import HeaderSection from "../../components/header";
import CommonHeroSection from "../../components/CommonHeroSection/CommonHeroSection";
import ChimneySweepSection from "./ChimneySweepSection";
import Footer from "../../components/footerSection";
import image2 from "../../assets/installationBg.png";
import OurGlorySection from "../../components/OurGlorySection";
import {listOfFaqInstallation} from '../../components/listofData'

import faqImg from "../../assets/installationImg.png";
import ContactUsSection from "../../components/servicesSection/ContactUsSection";
import FAQsSection from "../../components/FAQsSection";
import imgInstallationSection from "../../assets/installationSection.png";
import installatiionbg from '../../assets/installatiionbg.png'
import Installedglory1 from "../../assets/Installedglory1.jpg";
import Installedglory2 from "../../assets/Installedglory2.jpg";
export default function ServicePageInstallation() {

  let images = [Installedglory1, Installedglory2];

  return (
    <div>
      <HeaderSection />
      <CommonHeroSection
        imgName={image2}
        titleHead="Installations"
        extraContent={`Home > Our Services > Installations`}
      />
      <ChimneySweepSection
      bgImg={installatiionbg}
        backgroundImage={imgInstallationSection}
        trueAfter={true}
        firstContent={`Crafting comfort since decades: `}
        secSentence={"Get your"}
        highlightText={"fireplaces and stoves installed"}
        thirdSentence={"with us today!"}
        // firstSentence={`Crafting comfort since decades:
        // Get your`}
        // secSentence={` fireplaces and stoves installed`}
        // thirdSentence={`with us today!`}
        title={`Installations:`}
        subTitle={`Whatever you need, 
                whenever you need!`}
        extraContent={`Leduc Chimney Sweep Ltd. isn't just about keeping your existing 
                fireplace safe and efficient. We're your complete hearth and home
                experts! We offer a wide range of fireplace and stove installations,
                 parts, and maintenance. Looking to create a cozy indoor fireplace? 
                We can help you choose and install the perfect model for your space.
                 Our team can bring your vision to life with stunning outdoor
                 fireplace options. Contact us today and let's get started on your
                 fireplace project!`}
      />
      <FAQsSection
        firstSentence={`The Art of Comfort: Customized Fireplaces & Stoves Installation By `}
        secSentence={` Leduc Chimney Sweep Ltd.`}
        thirdSentence={``}
        ExpandDataAll={listOfFaqInstallation}
        faqImg={faqImg}
      />
      {/* <OurGlorySection 
      images={images}
      /> */}
      <ContactUsSection />
      <Footer />
    </div>
  );
}
