import React, { useEffect, useState } from 'react'
import { IoBagRemoveSharp } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { BsCurrencyDollar } from "react-icons/bs";
import { useLocation, useNavigate } from 'react-router-dom';
import { jobOpeningsData } from './jobData';
import HeaderSection from '../components/header';
import CommonHeroSection from '../components/CommonHeroSection/CommonHeroSection';
import image2 from ".././assets/gallerybgimg.png";
import Footer from '../components/footerSection';


const JobDetails = () => {

  const location = useLocation();
  const jobNewId = location.state?.jobId;

  const navigate = useNavigate()

 const [jobDetails, setJobDetails] = useState([])

  useEffect(()=>{
const filterData = jobOpeningsData.filter((data)=>{
  return data.id == jobNewId
})
setJobDetails(filterData[0])

  })


  return <>
<HeaderSection/>
<CommonHeroSection
   imgName={image2}
        titleHead="Job Details"
        extraContent={`Home > Job Details`}

/>
  
  <div className='container border-1 jobdetailsMaineContainer mb-3'>
    <div className='row'>
      <div className='col-12 d-flex align-items-center justify-content-between'>
        <h5>{jobDetails?.title}</h5>
        <button className='backJobPageBtn' onClick={()=>{
          navigate("/ouropenings")
        }}>Back</button>
      </div>
      <hr/>
      {/* <div className='jobHeader'>
                    <div className='jobIconDiv'>
                        <IoBagRemoveSharp/>
                    </div>
                    <div className='jobHeadTitle'>
                        <h5>Fireplace Technician apprentice, Labourer</h5>
                        <div className='jobHeadInner'>
                            <div className='jobLocationDiv'>
                                <div className='jobLocatioIconDiv'>
                                <FaLocationDot/>
                                </div>
                                
                            </div>
                            <span>Leduc</span>
                        </div>
                    </div>
        </div> */}
        <div className='jobMiddleContent'>
             <div className='col-md-6 col-12 d-flex align-items-center'>
                <div className='jobLocationDiv'>
                                <div className='jobLocatioIconDiv'>
                                <IoBagRemoveSharp/>
                                </div>
                                
                            </div>
                            <span>{jobDetails?.Experience}</span>
                            </div>
                            <div className='col-md-6 col-12 d-flex align-items-center'>
                            <div className='jobLocationDiv'>
                                <div className='jobLocatioIconDiv'>
                                <BsCurrencyDollar/>
                                </div>
                                
                            </div>
                            <span>{jobDetails?.salary}</span>
                            </div>
                </div>
        <div className='col-12'>
          <h3>About company</h3>
          <hr/>
          <p>The Fireplace Store Leduc Chimney Sweep Ltd. supplies and installs fireplaces, stoves, inserts and chimneys. We also provide Chimney cleaning, Inspections & Repairs. During the warmer months we rebuild brick chimneys and complete masonry work.</p>
        </div>
        <div className='col-12 sechduleDiv'>
          <h5>Schedule </h5>
          <ul>
          {
            jobDetails?.sechdule &&  jobDetails?.sechdule.map((data,index)=>{
              return <li key={index}>{data}</li>
            })
          }

          </ul>
        </div>
        <div className='col-12 sechduleDiv'>
          <h5>Supplemental pay types:  <span>{jobDetails?.Supplementalpaytypes}</span></h5>
        </div>
        <div className='col-12 sechduleDiv'>
          <h5>Work remotely : <span>{jobDetails?.Workremotely}</span></h5>
        </div>
        <div className='col-12 sechduleDiv'>
          <h5>Job Responsibilities</h5>
          <hr/>
          <ul>
            {
              jobDetails?.JobResponsibilities && jobDetails?.JobResponsibilities.map((data,index)=>{
                return <li key={index}>{data}</li>
              })
            }
            
          </ul>
        </div>
    </div>
    <div className='jobDetailsFooter'>
      <button>Please send in your resume at sonia@leducchimneysweep.com for shortlisting. </button>
    </div>
  </div>
  <Footer/>
  </>
}

export default JobDetails