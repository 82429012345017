import React from 'react'
import './service.css'

export default function GallarySection(props) {
    console.log('props', props)
    return (
        <div>
            <div className="same_width_container">
                <div className='gallery_row_container'>
                    <div className="gallary_left_container">
                        <div claasName="gallary_img_container">
                            <img src={props.gallaryImg} alt='imgGallary' style={{width:"100%",height:"100%"}} loading='lazy' />
                        </div>
                    </div>
                    <div className="gallary_rgt_container">
                        <h6>{props.firstSent} <span>{props.secSent}</span></h6>

                        <p>{props.extraContent}</p>
                    </div>
                </div>
                <div className='gallery_row_container1'>
                    <div className="gallary_left_container">
                        <div claasName="gallary_img_container">
                            <img src={props.gallaryImg1} alt='imgGallary' loading='lazy' />
                        </div>
                    </div>
                    <div className="gallary_rgt_container gallary_rgt_container1 ">
                        <h6>{props.firstSent1} <span>{props.secSent1}</span></h6>

                        <p>{props.extraContent1}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
