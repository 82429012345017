import React from "react";
import { Link, NavLink } from "react-router-dom";
import chevron from "../../assets/right_chevron.svg";
import call1 from "../../assets/call1.svg";
import mail1 from "../../assets/mail1.svg";
import map1 from "../../assets/map1.svg";
import logo from "../../assets/logo.png";
import fb from "../../assets/fb.svg";
import linkedin from "../../assets/linkedin.svg";
import insta from "../../assets/insta.svg";
import "./footer.css";

const Footer = () => {
  const iconImg = [
    {
      iconName: map1,
      iconText: "#4 5907-45 Street Leduc, AB T9E 7B3, Canada",
    },
    {
      iconName: mail1,
      iconText: "info@leducchimneysweep.com",
    },
    {
      iconName: call1,
      iconText: "780-986-1181",
    },
  ];

  return (
    <>
   
      <div className="footer_container">
      {/* <p className="title_footer_title_text same_width_container">
                The Best <span>Chimney Sweeping</span> <br/> Company in Canada{" "}
              </p>   */}
        <div className="footer_content same_width_container">
          <div className="footer_content_left">
            
            <div className="logo_contact_icon_container">
             
              {/* <div className="logo_icon_container">
              <img src={logo} alt="logoImg" />
            </div> */}

              <div className="icon_text_wrapper">
                {iconImg?.map((item, index) => {
                  return (
                    <div className="contact_icon_text_container" key={index}>
                      <div className="icon_container">
                      <div className="footerIconDiv">
                        <img src={item?.iconName} alt="logoImg" />
                        </div>
                      </div>
                      <p>{item?.iconText}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="footer_content_right">
            <div className="quick_link_container">
              <h6>Quick Links</h6>
              <NavLink to="/">
                <img src={chevron} alt="icon" />
                Home
              </NavLink>
              <NavLink to="/ServicePage">
                {" "}
                <img src={chevron} alt="icon" /> Our Services
              </NavLink>
              <NavLink to="/AboutUs">
                {" "}
                <img src={chevron} alt="icon" /> About Us
              </NavLink>
              <NavLink to="/ContactUspage">
                {" "}
                <img src={chevron} alt="icon" /> Contact Us
              </NavLink>
              <NavLink to="/OurGallary">
                {" "}
                <img src={chevron} alt="icon" /> Gallery
              </NavLink>
            </div>

            <div className="newsletter_social_icon_container">
              <h6>Subscribe to Newsletters</h6>
              <div className="newsletter_container">
                <input type="text" placeholder="Enter Email" />

                <button>Subscribe</button>
              </div>
              <div className="social_icon_container">
                {/* <img src={fb} alt="logoImg" />
                <img src={insta} alt="logoImg" />
                <img src={linkedin} alt="logoImg" /> */}
                  <a href="https://www.facebook.com/leducchimney/" target="_blank" rel="noopener noreferrer">
        <img src={fb} alt="Facebook Logo" />
      </a>
      <a href="https://www.instagram.com/leducchimneysweep/" target="_blank" rel="noopener noreferrer">
        <img src={insta} alt="Instagram Logo" />
      </a>
      {/* <a  target="_blank" rel="noopener noreferrer">
        <img src={linkedin} alt="LinkedIn Logo" />
      </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright_text_container">
        <p>
          ©2023 <span>Leduc Chimney Sweep Ltd</span> All rights reserved.
        </p>
      </div>
    </>
  );
};

export default Footer;
