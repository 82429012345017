import React, { useEffect, useState } from "react";
import HeaderSection from "../../components/header";
import HeroSection from "../../components/herosection";
import image from "../../assets/pngegg.png";

import PurposeOfSweep from "./PurposeOfSweep";
import CommonHeroSection from "../../components/CommonHeroSection/CommonHeroSection";
import WhereItStartedSection from "./WhereItStartedSection/WhereItStartedSection";
import InstalledSection from "./InstalledSection";
import image1 from "../../assets/about_us_bg.png";
import image2 from "../../assets/about2.png";

import Footer from "../../components/footerSection";
import TestimonialSection2 from "../../components/TestimonialSection2";
import OurGlorySection from "../../components/OurGlorySection";
import QualityAndAssurance from "./QualityAndAssurance";
import FaqComponent from "../faq/FAQPage/FaqComponent";
import { listOfFaq } from "../../components/listofData";
import about1 from '../../assets/aboutglory1.jpg'
import about2 from '../../assets/aboutglory2.jpg'
import about3 from '../../assets/aboutglory3.jpg'
import about4 from '../../assets/aboutglory4.jpg'
import about5 from '../../assets/aboutglory5.jpg'

function AboutUs() {



  let images = [about1, about3, about4]





  return (
    <div className="AboutUs">
      <HeaderSection />
      <CommonHeroSection 
      imgName={image1}
      titleHead="About Us"
      extraContent={`Home > About Us`}
      />
      <WhereItStartedSection />
      {/* <PurposeOfSweep /> */}
      <OurGlorySection
      images={images}
      />
      <InstalledSection />
      <QualityAndAssurance/>
      <TestimonialSection2 />
      {/* <FaqComponent
        firstSentence={`Frequently Asked `}
        secSentence={`Questions`}
        thirdSentence={`Sweep - Breathe Easy This Winter.`}
        ExpandDataAll={listOfFaq}
        // faqImg={faqImg}
      /> */}
      <Footer/>
    </div>
  );
}

export default AboutUs;
