import React from "react";
import "./flame.css";
import { Link } from "react-router-dom";

const ChooseYourFlame = () => {
  const flameOptions = [
    { id: 1, title: "Wood", image: require("../../assets/woodimage.svg"),
      navigate: "/ServicePageWood"

     },
    { id: 2, title: "Gas", image: require("../../assets/gasimage.svg"),
      navigate: "/ServicePageGas"
     },
    {
      id: 3,
      title: "Electric",
      image: require("../../assets/electricimage.svg"),
      navigate: "/ServicePageElectric",
    },
    { id: 4, title: "Pellet", image: require("../../assets/pelletimage.svg") ,
      navigate: "/ServicePagePellet"
    },
  ];

  return (
    <div className="same_width_container">
      <div
        className="choose_flame"
        // style={{ backgroundImage: `url(${require('../../assets/flameimage.png')})` }}
      >
        <div className="flame_content same_width_container">
          <h1 className="flame_title">
            Choose Your{" "}
            <p>
              <span className="f_text">F</span>LAME
            </p>
          </h1>
          <div className="flame_options">
            {flameOptions.map((item, index) => (
              <div className="flame_option" key={index}>
                {/* <p className='flame_selection_container'>0{index + 1}</p> */}
                <Link  to={item?.navigate}>

                <img
                  src={item.image.default}
                  alt="Flame"
                  className="flame_image"
                />
                  </Link>
                <h2 className="flame_option_title">{item.title}</h2>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseYourFlame;
