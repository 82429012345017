import React, { useState } from "react";
import HeaderSection from "../../components/header";
import image2 from "../../assets/gallerybgimg.png";
import CommonHeroSection from "../../components/CommonHeroSection/CommonHeroSection";
import Footer from "../../components/footerSection";
import GetInTouch from "./GetInTouch";
import MapComponent from "../../components/Map/MapComponent";

export default function ContactUsPage() {
  const initialCenter = [53.2638, -113.5568]; // Leduc, Alberta
  // Centered on Edmonton, Alberta


  const [center, setCenter] = useState(initialCenter);

  // Define the marker positions
  const markers = [
    { position: [53.5461, -113.4938], text: "Edmonton" },
    { position: [53.2638, -113.5568], text: "Leduc" },
    { position: [53.2488, -113.8677], text: "Calmar" },
    { position: [53.3581, -114.4077], text: "Warburg" },
    { position: [53.7769, -114.6812], text: "Lac Ste. Anne County" },
    { position: [53.7654, -114.2021], text: "Glenevis" },
    { position: [53.5482, -114.1337], text: "Gunn" },
    { position: [53.7974, -114.2324], text: "Onoway" },
    { position: [53.5494, -114.3549], text: "Alberta Beach" },
    { position: [53.6593, -114.9588], text: "Darwell" },
    { position: [53.5518, -114.9794], text: "Wabamum" },
    { position: [53.5298, -114.8994], text: "Gainford" },
    { position: [53.5280, -114.8551], text: "Entwistle" },
    { position: [53.5926, -114.9987], text: "Ross Haven" },
    { position: [53.6286, -114.7484], text: "West Cove" },
    { position: [53.6615, -114.9224], text: "Lake Isle" },
    { position: [53.6324, -114.8772], text: "Silver Sands" },
    { position: [53.6816, -114.8747], text: "Magnolia" },
    { position: [53.5235, -114.8950], text: "Gainford" },
    { position: [53.5503, -114.6428], text: "Seba Beach" },
    { position: [53.5807, -114.8780], text: "Fallis" },
    { position: [53.5140, -114.9123], text: "Wabamum Lake" },
    { position: [53.5404, -114.6855], text: "Langford Park" },
    { position: [53.5544, -114.7270], text: "Duffield" },
    { position: [53.4656, -114.3170], text: "Carvel" },
    { position: [53.7193, -114.5149], text: "Spring Lake" },
    { position: [53.5582, -114.9801], text: "Clearwater Estates" },
    { position: [53.5410, -114.9802], text: "Stony Plain" },
    { position: [53.5251, -114.9057], text: "Highvale" },
    { position: [53.5508, -114.9735], text: "Mewassin" },
    { position: [53.5458, -113.9032], text: "Spruce Grove" },
    { position: [53.5031, -114.0751], text: "Acheson" },
    { position: [53.4144, -114.0854], text: "Genesee Lake" },
    { position: [53.4414, -113.4605], text: "Pemburton Hill" },
    { position: [53.4460, -113.6365], text: "Telfordville" },
    { position: [53.5573, -114.9544], text: "Saint Francis" },
    { position: [53.3001, -114.7251], text: "Lindale" },
    { position: [53.2243, -114.9450], text: "Carnwood" },
    { position: [53.2188, -114.9825], text: "Drayton Valley" },
    { position: [53.6520, -114.9298], text: "Tomahawk" },
    { position: [53.3552, -115.0498], text: "Rocky Rapids" },
    { position: [53.0968, -114.3867], text: "Buck Creek" },
    { position: [53.0971, -114.3835], text: "Breton" },
    { position: [52.9966, -114.0038], text: "Winfield" },
    { position: [53.0920, -114.1255], text: "Battle Lake" },
    { position: [53.0857, -113.6264], text: "Pigeon Lake" },
    { position: [53.0717, -112.8629], text: "Pipestone" },
    { position: [53.3636, -113.5619], text: "Thorsby" },
    { position: [53.1097, -113.4701], text: "Millet" },
    { position: [53.0913, -113.3242], text: "Falun" },
    { position: [53.2574, -113.4563], text: "Wetaskiwin" },
    { position: [52.9217, -113.6359], text: "Maskwacis" },
    { position: [52.8946, -113.5655], text: "Gwynne" },
    { position: [52.9824, -113.2256], text: "Bittern Lake" },
    { position: [53.0220, -112.8140], text: "Armena" },
    { position: [53.0191, -112.8471], text: "Camrose" },
    { position: [53.2437, -112.7640], text: "Hay Lakes" },
    { position: [53.2684, -113.1991], text: "New Sarepta" },
    { position: [53.2626, -112.8906], text: "Round Hill" },
    { position: [53.2371, -112.9451], text: "Kingman" },
    { position: [53.3676, -112.6621], text: "Tofield" },
    { position: [53.4413, -112.7312], text: "Lindbrook" },
    { position: [53.2447, -113.2773], text: "Deville" },
    { position: [53.5244, -113.5246], text: "Uncas" },
    { position: [53.5444, -113.4909], text: "Beaumont" },
    { position: [53.3097, -113.5829], text: "Nisku" },
    { position: [53.3689, -113.4170], text: "Devon" },
    { position: [53.5413, -113.2988], text: "Sherwood Park" },
    { position: [53.9246, -113.3174], text: "Fort Saskatchewan" },
    { position: [53.8124, -113.3253], text: "St. Albert" },
    { position: [53.8344, -113.3191], text: "Gibbons" },
    { position: [53.9491, -113.2052], text: "Legal" },
    { position: [53.9451, -113.1087], text: "Redwater" },
    { position: [53.3850, -112.8050], text: "South Cooking Lake" },
  ];

  // Function to handle location button click
  const handleLocationClick = (position) => {
    setCenter(position);
  };
  return (
    <div>
      <HeaderSection />
      <CommonHeroSection
        imgName={image2}
        titleHead="Contact Us"
        extraContent={`Home > Contact Us`}
      />

      <GetInTouch />
     <div className="mapHeadingDiv">
      <h4>Our Service Areas</h4>
     </div>
        <MapComponent center={center} markers={markers} onLocationClick={handleLocationClick} />
      <Footer />
    </div>
  );
}
