
import React from "react";
import image1 from "../../assets/repairimage1.png";
import image2 from "../../assets/image2.png";
import arrow from '../../assets/arrow.png'

import serviceman from '../../assets/chimneySweepBg1.png'
import './service.css';

const ChimneySweepServiceSection = () => {

    let services = [
        {
            id: 1,
            title: 'Stainless steel insulated chimney',
        },
        {
            id: 2,
            title: 'Stainless steel chimney liners',
        },
        {
            id: 3,
            title: 'Clay flues ',
        },
    ];
 
    return (
        <div className="service_container ">
            <div className="service_content">
            <div className="service_content_left">
                    <div className="service_left_img_container">

                    <img src={serviceman} alt="Service" className="service_image1" />
                    </div>
                    {/* <img src={image2} alt="Service" className="service_image2" /> */}
                </div>
                <div className="service_content_right">
                    <h1 className="service_title">WHY Leduc <br/> <p>Chimney Sweep?</p></h1>
                    <p className="service_description">You can be confident our technicians have the required training and experience
to provide you with the professional services needed to maintain your wood
burning appliance. <br/>We sweep :</p>
                    <div className="service_list">
                        {services.map((item, i) => (
                            <div className="service_list_item_container" key={i}>
                                <img src={arrow} />
                                <p className="service_list_item_title">{item?.title}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ChimneySweepServiceSection;