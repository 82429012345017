import React from "react";
import HeaderSection from "../../components/header";
import CommonHeroSection from "../../components/CommonHeroSection/CommonHeroSection";
import Footer from "../../components/footerSection";
import image2 from "../../assets/gasbgimg.png";
import ContactUsSection from "../../components/servicesSection/ContactUsSection";
import GallarySection from "./GallarySection";
import gImg from "../../assets/gallaryGas1.png";
import gImg1 from "../../assets/gasestoveimg.png";
import WhyChooseSection from "./WhyChooseSection";
import FaqComponent from "../faq/FAQPage/FaqComponent";
import gasstove1 from "../../assets/gasstove1.png";

export default function ServicePageGas() {
  let gallaryData = [
    {
      gallaryImg: gImg,
      headText: `Gas Burning`,
      highlightText: `Fireplace`,
      extraText: `Features realistic-looking logs or contemporary fire glass
            over a burner fueled by natural gas or propane. 
            Gas fireplaces offer instant heat and adjustable flame
            settings, allowing homeowners to easily control the
            temperature and ambiance of their space.
            They are highly efficient and low-maintenance.`,
      gallaryImg1: gasstove1,
      headText1: `Gas Burning`,
      highlightText1: `Stoves`,
      extraText1: `Gas Burning Stoves Mimic the look of traditional wood
            stoves but operate on gas fuel, offering convenience 
           and ease of use. They offer efficient heating for specific
           rooms or areas and can be vented through a wall or 
           existing chimney.`,
    },
  ];

  let titlesBox = [
    "Cleaner Air",
    "Instant Heat",
    "Minimal Energy Consumption",
    "Minimal Efforts required",
  ];
  
  const listOfFaq  = [
    {
    question: "What type of gas line is needed for my gas fireplace or stove?",
    answer: `The type of gas line (natural gas or propane) depends on your existing gas supply. A qualified gas fitter can advise you on the appropriate connection.
`,
    },
    {
    question: "Can I extend my existing gas line for a new fireplace or stove?",
    answer: `Gas line extension should only be done by a licensed and certified gas fitter. They will ensure proper sizing, materials, and safety regulations are followed.

`,
    },
    {
    question: "Are gas fireplaces eco-friendly?",
    answer: `Gas fireplaces are generally considered more efficient than traditional wood-burning fireplaces.
`,
    },
  
];
  return (
    <div>
      <HeaderSection />
      <CommonHeroSection
        imgName={image2}
        titleHead="Gas"
        extraContent={`Home > Our Services >Gas`}
      />

    
      <GallarySection
        gallaryImg={gallaryData[0]?.gallaryImg}
        extraContent={gallaryData[0]?.extraText}
        secSent={gallaryData[0]?.highlightText}
        firstSent={gallaryData[0]?.headText}
        gallaryImg1={gallaryData[0]?.gallaryImg1}
        extraContent1={gallaryData[0]?.extraText1}
        secSent1={gallaryData[0]?.highlightText1}
        firstSent1={gallaryData[0]?.headText1}
      />
        <WhyChooseSection
        firstSent={"Why Choose"}
        secSent={"Gas Flame?"}
        titlesBox={titlesBox}
      />
        <FaqComponent
        firstSentence={`Frequently Asked `}
        secSentence={`Questions`}
        thirdSentence={`Sweep - Breathe Easy This Winter.`}
        ExpandDataAll={listOfFaq}
        // faqImg={faqImg}
      />


      <ContactUsSection />

      <Footer />
    </div>
  );
}
