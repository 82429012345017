import React from "react";
import "./partnershipsectopn2.css";
import profile_pic from "../../assets/profile_pic.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay"

import { Pagination, Navigation, Autoplay } from "swiper/modules";
import imagep1 from '../../assets/imagep1.png'
import imagep2 from '../../assets/imagep2.jpg'
import imagep3 from '../../assets/imagep3.png'
import imagep4 from '../../assets/imagep4.png'
import imagep5 from '../../assets/imagep5.svg'
import imagep6 from '../../assets/imagep6.png'
import imagep7 from '../../assets/imagep7.svg'
import imagep8 from '../../assets/imagep8.png'
import imagep9 from '../../assets/imagep9.svg'
import imagep10 from '../../assets/imagep10.svg'
import imagep11 from '../../assets/imagep11.svg'
import imagep12 from '../../assets/imagep12.svg'
import imagep13 from '../../assets/imagep13.svg'
import imagep14 from '../../assets/imagep14.svg'
import imagep15 from '../../assets/imagep15.jpg'

export default function PartnershipSection2() {

  let images = [{
    imagep1:imagep1,
    imagep2:imagep2,
    imagep3 :imagep3
  },
  {
    imagep1 :imagep4,
    imagep2 :imagep5,
    imagep3 :imagep6
  },
  
  {
    imagep1 :imagep10,
    imagep2 :imagep11,
    imagep3 :imagep12
  },
  {
    imagep1 :imagep13,
    imagep2 :imagep14,
    imagep3 :imagep15
  },
  {
    imagep1:imagep1,
    imagep2 :imagep8,
    imagep3 :imagep9
  },
 
 

]


  return (
    <div className="same_width_container bg_partnership_section2_bg">
      <div className="title_content_box_container">
        <h6>Partnerships & Accreditation: Our Guarantee of Quality Service</h6>
        <p>
          We have a team of highly qualified technicians, each certified with
          WETT certifications for Levels 1, 2, and 3 inspections. Our
          technicians hold an extensive experience within the industry,
          guaranteeing a deep understanding of fireplace systems and the ability
          to deliver exceptional service.
        </p>
        <div className="box_partnership_section">
        <Swiper
         pagination={{
          clickable: true,
        }}
          slidesPerView={1}
          spaceBetween={30}
         

          modules={[Pagination, Navigation, Autoplay]}
          autoplay={{ delay: 2500, disableOnInteraction: false }} // Autoplay settings
          className="mySwiper swiperTestimonial"
        >
          {images?.map((item) => {
            return (
              <SwiperSlide>
                <div className="box_container_slider_partner_two">
                  <img
                    className="testinomial_self"
                    src={item?.imagep1}
                    alt="qoute"
                    loading="lazy"
                  />
                  <img
                    className="testinomial_self"
                    src={item?.imagep2}
                    alt="qoute"
                    loading="lazy"
                  />
                  <img
                    className="testinomial_self"
                    src={item?.imagep3}
                    alt="qoute"
                    loading="lazy"
                  />
              
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        </div>
      </div>
    </div>
  );
}
