import React from 'react'
import { IoBagRemoveSharp } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { BsCurrencyDollar } from "react-icons/bs";
import { useNavigate } from 'react-router-dom'

const JobCard = ({data}) => {


    const navigate = useNavigate()


  return  <div className='col-lg-4 col-md-6 col-12'>
               <div className='jobCardDiv'>
                <div className='jobHeader'>
                    <div className='jobIconDiv'>
                        <IoBagRemoveSharp/>
                    </div>
                    <div className='jobHeadTitle'>
                        <h5>{data.title}</h5>
                        <div className='jobHeadInner'>
                            <div className='jobLocationDiv'>
                                <div className='jobLocatioIconDiv'>
                                <FaLocationDot/>
                                </div>
                                
                            </div>
                            <span>Leduc</span>
                        </div>
                    </div>
                </div>
                <div className='jobMiddleContent'>
                <div className='jobLocationDiv'>
                                <div className='jobLocatioIconDiv'>
                                <IoBagRemoveSharp/>
                                </div>
                                
                            </div>
                            <span>{data.Experience}</span>
                            <div className='jobLocationDiv'>
                                <div className='jobLocatioIconDiv'>
                                <BsCurrencyDollar/>
                                </div>
                                
                            </div>
                            <span>{data.salary}</span>
                </div>
                <div className='jobFooter'>
                    <button onClick={()=>{
                        navigate("/jobdetails", {
                            state: { jobId : data.id }
                            
                        })
                    }}>View Job</button>
                </div>
               </div>     
  </div>
}

export default JobCard