import React from "react";
import image1 from "../../assets/repairimage1.png";
import image2 from "../../assets/image2.png";
import arrow from '../../assets/arrow.png'

import './service.css';

const ServiceSection = () => {

    let services = [
        {
            id: 1,
            title: 'WETT Certified Chimney Sweeping ',
        },
        {
            id: 2,
            title: 'WETT Certified Inspections ',
        },
        {
            id: 3,
            title: 'WETT Certified Installations',
        },
        {
            id: 4,
            title: 'Fireplace Servicing',
        },
        {
            id: 5,
            title: 'Brick/Masonry repairs',
        },
        {
            id: 6,
            title: 'Fireplace Supplies ',
        },
    ];
 
    return (
        <div className="service_container ">
            <div className="service_content">
            <div className="service_content_left">
                    <div className="service_left_img_container">

                    <img src={image2} alt="Service" className="service_image1" />
                    </div>
                    {/* <img src={image2} alt="Service" className="service_image2" /> */}
                </div>
                <div className="service_content_right">
                    <h1 className="service_title">Offering Warmth<br/> <p>Season After Season:</p></h1>
                    <p className="service_description">The Fireplace Store by Leduc Chimney offers a range of services designed to keep your fireplace
                    burning safely. These include:</p>
                    <div className="service_list">
                        {services.map((item, i) => (
                            <div className="service_list_item_container" key={i}>
                                <img src={arrow} />
                                <p className="service_list_item_title">{item?.title}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ServiceSection;