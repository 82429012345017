import React from "react";
import HeaderSection from "../../components/header";
import CommonHeroSection from "../../components/CommonHeroSection/CommonHeroSection";
import Footer from "../../components/footerSection";
import image2 from "../../assets/electricbgimage.png";
import ContactUsSection from "../../components/servicesSection/ContactUsSection";
import GallarySection from "./GallarySection";
import gImg from "../../assets/electricfireplaceimg.png";
import gImg1 from "../../assets/electricinsertimg.png";
import WhyChooseSection from "./WhyChooseSection";
import './service.css'
import FaqComponent from "../faq/FAQPage/FaqComponent";
import { listOfFaq } from "../../components/listofData"; 

export default function ServicePageElectric() {
  let gallaryData = [
    {
      gallaryImg: gImg,
      headText: `Electric`,
      highlightText: `Fireplace`,
      extraText: `Electric Fireplaces It comes in various styles, from 
            mantel packages to wall-mounted units. They feature 
            realistic flame effects generated by LED technology,
            along with adjustable heat settings that allow 
            homeowners to control the temperature to their liking. 
            They are easy to install and can be placed in any room, 
            making them an ideal choice for apartments, condos, 
            or homes without existing chimneys.`,
      gallaryImg1: gImg1,
      headText1: `Electric`,
      highlightText1: `Inserts`,
      extraText1: `Electric Inserts These are designed to retrofit into existing 
            fireplaces, converting them into electric heating sources. 
            They are easy to install and operate, making them a 
            cost-effective and low-maintenance alternative to 
            traditional wood or gas inserts. Plus, they can be used 
            with or without heat, allowing homeowners to enjoy the 
            visual appeal of a fire year round.`,
    },
  ];

  let titlesBox = [
    "Customizable",
    "Easy Installation",
    "Minimal Maintenance",
    "Safest Option",
  ];
 
  const listOfFaq  = [
    {
    question: "What factors should I consider when choosing an electric fireplace or insert?",
    answer: `Heating needs, Fireplace vs. insert, Size and style, Features.
`,
    },
    {
    question: "How often should I have my electric fireplace or insert serviced? ",
    answer: `Generally, electric fireplaces and inserts require minimal maintenance and don't need regular servicing. However, it's a good idea to have a qualified electrician inspect the unit periodically (every few years) to ensure electrical safety.

`,
    },
    {
    question: "What are signs my electric fireplace or insert needs repair?",
    answer: `The need of repair can be reflected if the unit won't turn on, the heater doesn't work or the remote control doesn't work thereby affecting the proper functioning of the Unit.
`,
    },
    {
      question: "What factors should I consider when choosing an electric fireplace or insert?",
      answer: `The factors to be taken into consideration are as follows Heating needs, Fireplace for ambiance, Size and style or Features.`
      },
  ]



  return (
    <div>
      <HeaderSection />
      <CommonHeroSection
        imgName={image2}
        titleHead="Electric"
        extraContent={`Home > Our Services >Electric`}
      />

      <GallarySection
        gallaryImg={gallaryData[0]?.gallaryImg}
        extraContent={gallaryData[0]?.extraText}
        secSent={gallaryData[0]?.highlightText}
        firstSent={gallaryData[0]?.headText}
        gallaryImg1={gallaryData[0]?.gallaryImg1}
        extraContent1={gallaryData[0]?.extraText1}
        secSent1={gallaryData[0]?.highlightText1}
        firstSent1={gallaryData[0]?.headText1}
      />

      <WhyChooseSection
        firstSent={"Why Choose"}
        secSent={"Electric Flame?"}
        titlesBox={titlesBox}
      />
        <FaqComponent
        firstSentence={`Frequently Asked `}
        secSentence={`Questions`}
        thirdSentence={`Sweep - Breathe Easy This Winter.`}
        ExpandDataAll={listOfFaq}
        // faqImg={faqImg}
      />

      <ContactUsSection />

      <Footer />
    </div>
  );
}
