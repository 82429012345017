import React from "react";
import HeaderSection from "../../components/header";
import image2 from "../../assets/gallerybgimg.png";
import CommonHeroSection from "../../components/CommonHeroSection/CommonHeroSection";
import Footer from "../../components/footerSection";
import faqImg from "../../assets/faqImg.png";
import FaqComponent from "./FAQPage/FaqComponent";
import GetInTouch from "../contactUsPage/GetInTouch";
import { listOfFaq } from "../../components/listofData";

export default function Faq() {
  const ExpandDataAll = [
    {
      question: "WHY to get your chimney Swept?",
      answer: `You need to clean your chimney to prevent chimney fires and ensure efficient 
                burning. Soot buildup is flammable, and a clean chimney allows proper airflow 
                for optimal heat and safety.`,
    },
    {
      question: "WHEN to get your Chimney Swept?",
      answer: `You need to clean your chimney to prevent chimney fires and ensure efficient 
                  burning. Soot buildup is flammable, and a clean chimney allows proper airflow 
                  for optimal heat and safety.`,
    },
    {
      question: "HOW to Prepare your chimney for sweeping?",
      answer: `You need to clean your chimney to prevent chimney fires and ensure efficient 
                  burning. Soot buildup is flammable, and a clean chimney allows proper airflow 
                  for optimal heat and safety.`,
    },
    {
      question: "WHAT Happens when your chimney is not cleaned?",
      answer: `You need to clean your chimney to prevent chimney fires and ensure efficient 
                  burning. Soot buildup is flammable, and a clean chimney allows proper airflow 
                  for optimal heat and safety.`,
    },
  ];

  return (
    <div>
      <HeaderSection />
      <CommonHeroSection
        imgName={image2}
        titleHead="FAQ"
        extraContent={`Home > FAQ`}
      />
      <FaqComponent
        firstSentence={`Frequently Asked `}
        secSentence={`Questions`}
        thirdSentence={`Sweep - Breathe Easy This Winter.`}
        ExpandDataAll={listOfFaq}
        faqImg={faqImg}
      />

      <GetInTouch />

      <Footer />
    </div>
  );
}
