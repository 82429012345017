import React from 'react'
import callCircle from '../../../assets/callCircle.svg'
import './contactussection.css'

export default function ContactUsSection() {
  return (
    <div className='black_contact_us_wrapper'>
        <p>Contact Us At</p>
        <div className='call_circle_number_container'>
            <img src={callCircle} alt='callCircle' loading='lazy' />
            <p>780-986-1181</p>
        </div>
    </div>
  )
}
