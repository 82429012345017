import React from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import img1 from "../../assets/img1.png";
import img2 from "../../assets/img2.png";
import "./ourglorysection.css";

export default function OurGlorySection({images}) {
  const dataImgs = [img1, img2, img1, img2, img1, img2];

  return (
    <div>
      <div className="same_width_container">
        <div className="title_left_rgt_navigation_container">
          <h6>
            Our work <span>Glory</span>
          </h6>

          <div className="left_right_swiper_btns">
            <button className="swiper-button-prev custom-swiper-button">
              <MdChevronLeft />
            </button>
            <button className="swiper-button-next custom-swiper-button">
              <MdChevronRight />
            </button>
          </div>
        </div>
        <div className="swiper_glory_section">
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
            }}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
          >
            {images?.map((item) => {
              return (
                <SwiperSlide>
                  <div className="img_swiper_container">
                    <img src={item} alt="img1" loading="lazy" />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
