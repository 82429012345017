
import React from 'react'
import ChooseYourFlame from '../../components/flameSection';
import HeaderSection from '../../components/header';
import HeroSection from '../../components/herosection';

import TypeOfService from '../../components/servicesSection';

import Footer from '../../components/footerSection';

import ExpertSection from '../../components/ExpertSection';
import PartnershipSection2 from '../../components/partnershipSection2';
import TestimonialSection2 from '../../components/TestimonialSection2';
import Faq from '../faq';
import FaqComponent from '../faq/FAQPage/FaqComponent';
import { listOfFaq } from '../../components/listofData';

function Dashboard() {
  const ExpandDataAll = [
    {
      question: "WHY to get your chimney Swept?",
      answer: `You need to clean your chimney to prevent chimney fires and ensure efficient 
                burning. Soot buildup is flammable, and a clean chimney allows proper airflow 
                for optimal heat and safety.`,
    },
    {
      question: "WHEN to get your Chimney Swept?",
      answer: `You need to clean your chimney to prevent chimney fires and ensure efficient 
                  burning. Soot buildup is flammable, and a clean chimney allows proper airflow 
                  for optimal heat and safety.`,
    },
    {
      question: "HOW to Prepare your chimney for sweeping?",
      answer: `You need to clean your chimney to prevent chimney fires and ensure efficient 
                  burning. Soot buildup is flammable, and a clean chimney allows proper airflow 
                  for optimal heat and safety.`,
    },
    {
      question: "WHAT Happens when your chimney is not cleaned?",
      answer: `You need to clean your chimney to prevent chimney fires and ensure efficient 
                  burning. Soot buildup is flammable, and a clean chimney allows proper airflow 
                  for optimal heat and safety.`,
    },
  ];

 
  
  return (
    <div className="Dashboard">
      <HeaderSection/>
      <HeroSection/>
      <div className="height_black_container">
        
      </div>
      <ExpertSection />
      {/* <InformationSection /> */}
      <ChooseYourFlame />
      <TypeOfService />
      {/* <PartnershipSection /> */}
      <PartnershipSection2 />
      <TestimonialSection2 />
    {/* <TestionialSection  /> */}
      <FaqComponent
        firstSentence={`Frequently Asked `}
        secSentence={`Questions`}
        thirdSentence={`Sweep - Breathe Easy This Winter.`}
        ExpandDataAll={listOfFaq}
        // faqImg={faqImg}
      />
   
      <Footer />
    </div>
  );
}

export default Dashboard;
