import React from "react";
import HeaderSection from "../../components/header";
import image2 from "../../assets/gallerybgimg.png";
import CommonHeroSection from "../../components/CommonHeroSection/CommonHeroSection";
import GallaryView from "./GallaryView";
import Footer from "../../components/footerSection";
import GetInTouch from "../contactUsPage/GetInTouch";

export default function OurGallary() {
  return (
    <div>
      <HeaderSection />
      <CommonHeroSection
        imgName={image2}
        titleHead="Our Gallery"
        extraContent={`Home > Our Gallery`}
      />
      <GallaryView />
      <GetInTouch />
      <Footer />
    </div>
  );
}
