export const jobOpeningsData = [
    {
        id : 1,
        title : "Fireplace Technician apprentice, Labourer",
        salary : "$20.00 - $25.00/hr",
        sechdule : [
            "8 hours shift",
           "Day, shift, Monday to Friday",

        ],
        Supplementalpaytypes : "Overtime pay",
        Experience : "Skilled Labor: 1 year (preferred)",
        Workremotely : "No",
        JobResponsibilities : [
             "Sweeping wood burning fireplace or wood burning stoves.",
             "Install gas and wood burning hearth appliances (this may be retrofitting an existing fireplace or adding a fireplace to an existing or new home ",
            "Read drawings and manufacturer’s instructions regarding the proper installation" ,
            "Use multiple power and hand tools proficiently",
            "Install metal fireplace vent materials to manufacturer’s specifications",
            "Understand and work with the building materials and construction of a home",
            "Maintain a clean and organized work vehicle and job site",
            "Safely move and install fireplaces.",
            "Attend regular company training"
        ]
    },
   
]