import React from "react";
import HeaderSection from "../../components/header";
import CommonHeroSection from "../../components/CommonHeroSection/CommonHeroSection";
import Footer from "../../components/footerSection";
import image2 from "../../assets/pelletebgimg.png";
import ContactUsSection from "../../components/servicesSection/ContactUsSection";
import GallarySection from "./GallarySection";
import gImg from "../../assets/pelletefireplaceimg.png";
import gImg1 from "../../assets/pelletestoveimg.png";
import WhyChooseSection from "./WhyChooseSection";
import FaqComponent from "../faq/FAQPage/FaqComponent";


export default function ServicePagePellet() {
  let gallaryData = [
    { 
      gallaryImg: gImg,
      headText: `Pellet`,
      highlightText: `Fireplace`,
      extraText: `Pellet Stoves Features a hopper that automatically
            feeds pellets into the combustion chamber, regulated 
            by a thermostat or manual controls. It requires minimal
            maintenance, with ash removal typically needed only
            once a week, making them a convenient and sustainable
            heating option for homes.`,
      gallaryImg1: gImg1,
      headText1: `Pellet`,
      highlightText1: `Stoves`,
      extraText1: `Pellet Fireplace Thes are built-in heating units and offers
            the beauty of a real flame without the hassle of chopping,
            storing, or hauling firewood. They can be vented through 
            a wall or existing chimney and are suitable for heating 
            specific rooms or areas within a home.`,
    },
  ];

  let titlesBox = [
    "Cost Efficient",
    "Minimal Ash Production",
    "Eco Friendly",
    "Sustainable Option",
  ];

  const listOfFaq  = [
    {
    question: "What type of pellet fuel should I use?",
    answer: `Use high-quality, dry pellets made from compressed wood. Avoid using wet pellets, treated wood, or other materials not approved for your specific appliance. These can damage the stove and create harmful emissions.
`,
    },
    {
    question: "What are Gasket replacement best practices?",
    answer: `Replace worn or damaged gaskets promptly to maintain proper airflow and prevent efficiency loss. Use high-temperature gasket material specifically designed for pellet appliances and ensure a tight seal during replacement.
`,
    },
    {
    question: "Are pellet stoves eco-friendly?",
    answer: `Pellet burning is generally considered a more sustainable heating option compared to traditional wood burning. However, emissions still occur.
`,
    },
  ]


  return (
    <div>
      <HeaderSection />
      <CommonHeroSection
        imgName={image2}
        titleHead="Pellet"
        extraContent={`Home > Our Services >Pellet`}
      />

      <GallarySection
        gallaryImg={gallaryData[0]?.gallaryImg}
        extraContent={gallaryData[0]?.extraText}
        secSent={gallaryData[0]?.highlightText}
        firstSent={gallaryData[0]?.headText}
        gallaryImg1={gallaryData[0]?.gallaryImg1}
        extraContent1={gallaryData[0]?.extraText1}
        secSent1={gallaryData[0]?.highlightText1}
        firstSent1={gallaryData[0]?.headText1}
      />
      <WhyChooseSection
        firstSent={"Why Choose"}
        secSent={"Pellet Flame?"}
        titlesBox={titlesBox}
      />
       <FaqComponent
        firstSentence={`Frequently Asked `}
        secSentence={`Questions`}
        thirdSentence={`Sweep - Breathe Easy This Winter.`}
        ExpandDataAll={listOfFaq}
        // faqImg={faqImg}
      />

      <ContactUsSection />
     

      <Footer />
    </div>
  );
}
