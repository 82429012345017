import React from "react";
import "./quality.css";
import trueImg from "../../../assets/qualityAndAssurance.png";

export default function QualityAndAssurance(props) {

    let listOfAssurance = [
        {
            title: "W.E.T.T. Certified Expertise",
            description: "We are proud to be W.E.T.T. certified, which means all our inspections, masonry work, chimney sweeping, and installations meet the highest standards for safety and efficiency."
        },
        {
            title: "All-Encompassing Chimney Services",
            description: `From sweeping to installations and everything in between, we offer a 
comprehensive suite of services to cover all aspects of chimney care. 
Our team is equipped to handle every chimney need, ensuring a one-
stop solution for our clients.`
        },
        {
            title: "Versatile Experience",
            description: `Our expertise isn't just limited to residential homes; we're also 
seasoned in catering to commercial properties. We tailor our services
 to meet the unique needs of each environment, ensuring the 
highest level of care and precision.`
        },
        {
            title: "Commitment to Your Satisfaction",
            description: `Our dedication to excellence is at the heart of everything we do. We 
take a personalized approach with every client, ensuring your 
chimney concerns are addressed with the utmost attention to detail 
and efficiency. Your satisfaction is our top priority.`
        }
    ]

  return (
    <div className="quality_assurance_bg_container">
      <div className="same_width_container">
        <h6 className="title_quality_text_self">
          {" "}
          Quality And <span>Assurance</span>
        </h6>
        <div className="quality_box_row_container">

             {listOfAssurance.map((item, index) => (
                        <div  className="quality_box_row_content">
                 <div className="quality_box_row_icon">
                   <img src={trueImg} alt="true" loading="lazy" />
                 <p className="title_text_box">{item.title}</p>
                 </div>
                 <p className="title_description_box">{item.description}</p>
    </div>
             ) )}
          
        </div>
      </div>
    </div>
  );
}
